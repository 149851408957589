import axios from "axios";
import dotenv from "dotenv";

dotenv.config();

let intervalRefresh = null;

export default async function auth({ next, router }) {
  const token = $cookies.get("token");
  const refreshToken = $cookies.get("refresh_token");

  if (!token && !refreshToken) {
    redirectToLogin();
    return;
  }

  if (!intervalRefresh) {
    await refresh();
    intervalRefresh = setInterval(refresh, 30000);
  }

  return next();

  async function refresh() {
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_HOSTNAME}/auth/refresh`,
        {},
        {
          headers: { authorization: refreshToken },
          timeout: 30000,
          maxBodyLength: Infinity,
        }
      );

      if (response.data.res_code === "00") {
        $cookies.set("token", response.data.res_result.token);
      } else {
        removeCookiesAndRedirect();
      }
    } catch (err) {
      if (err.response) {
        removeCookiesAndRedirect();
      } else {
        // window.location.reload();
      }
    }
  }

  function removeCookiesAndRedirect() {
    // $cookies.remove("token");
    // $cookies.remove("refresh_token");
    redirectToLogin();
  }

  function redirectToLogin() {
    window.location.href = "/login";
  }
}
