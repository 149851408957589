export const func = {
  checkPermission: (path) => {
    const category = localStorage.getItem("category");
    
    const result = category ? category.match(path) : null;

    if (result) {
      return true;
    } else {
      return false
    }
  },
};
